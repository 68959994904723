import React, { useState, useImperativeHandle, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 16
  },
  inputContainer: {
    paddingLeft: 30
  },
  input: {
    background: 'ivory',
  },
  helperText: {
    fontSize: 16
  },
  radioLabelRoot: {
    alignItems: 'end',
    padding: '9px 0px'
  },
  radioRoot: {
    padding: '0px 9px'
  }
}));

const MultipleChoiceQuestion = ({ question, data, setValue }, ref) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const value = Array.isArray(data) ? data : [];

  useEffect(() => {
    setError(false);
  }, [question]);

  function handleCheckChange(event, label) {
    const checked = event.target.checked;
    let newValue = [];
    if (checked) {
      newValue = [...value, label]
    } else {
      newValue = value.filter(item => item !== label);
    }
    setError(!newValue.length)
    setValue(newValue);
  }

  function validate() {
    if (!value.length) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  }

  useImperativeHandle(ref, () => ({ validate }));

  return (
    <Box>
      <h2>{question.label}</h2>
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <FormControl component="fieldset" error={error} >
          {question.choices.map((choice, index) => {

            let obj = choice;
            if (typeof obj === 'string') { // Support legacy
              obj = {};
              obj.id = choice;
              obj.name = choice;
            }
            return (
              <FormControlLabel
                key={index}
                classes={{ root: classes.radioLabelRoot }}
                control={
                  <Checkbox
                    classes={{ root: classes.radioRoot }}
                    checked={value.includes(obj.id)}
                    onChange={e => handleCheckChange(e, obj.id)}
                  />
                }
                label={<span className={classes.label}>{obj.name}</span>}
              />
            );
          })}
          <FormHelperText className={classes.helperText}>{error && "กรุณาเลือกอย่างน้อย 1 คำตอบ"}</FormHelperText>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default React.forwardRef(MultipleChoiceQuestion);
