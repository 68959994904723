import React, { useState, useImperativeHandle } from 'react'
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 16
  },
  inputContainer: {
  },
  input: {
    background: 'white',
  },
  helperText: {
    fontSize: 16,
    color: 'red'
  }
}));

const MultipleChoiceQuestion = ({ question, data, setValue }, ref) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const value = data || '';

  function validate() {
    if (!value) {
      setError(true);
      return false;
    }
    return true;
  }

  function handleValueChange(e) {
    setValue(e.target.value);
    setError(false);
  }

  useImperativeHandle(ref, () => ({ validate }));

  return (
    <Box>
      <h2>{question.label}</h2>
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <FormControl component="fieldset" error={error} >
          <Box className={classes.inputContainer}>
            <TextField
              className={classes.input}
              id="outlined-size-normal"
              variant="outlined"
              label="โปรดระบุตัวเลข"
              type="number"
              fullWidth
              value={value}
              onChange={handleValueChange}
              error={error}
            />
          </Box>
          <FormHelperText className={classes.helperText}>{ error && "กรุณาตอบคำถาม"}</FormHelperText>
        </FormControl>
      </Grid>
    </Box>
  )
}

export default React.forwardRef(MultipleChoiceQuestion);
