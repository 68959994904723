import React, { useState, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: 16
  },
  root: {
    height: 380,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const OneChoiceQuestion = ({ question, data, setValue, tryToNext = () => { }, center }, ref) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  const value = data == null ? '' : data;

  const onChoose = (data) => {
    setValue(data);
    setError(false);
    setTimeout(() => {
      tryToNext();
    }, 400);
  }

  function validate() {
    if (value === null || value === '') { // allow val to be 0
      setError(true);
      return false;
    }
    return true;
  }

  useImperativeHandle(ref, () => ({ validate }));

  return (
    <div className={center && classes.root}>
      <h2>{question.label}</h2>
      <Grid container spacing={2} justifyContent="center">
        {question.choices.map((choice, index) => {

          let obj = choice;
          if (typeof obj === 'string') { // Support legacy
            obj = {};
            obj.id = choice;
            obj.name = choice;
          }
          return (
            <Grid item xs={12} key={index}>
              {
                obj.id === value ? (
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={onChoose.bind(null, obj.id)}
                  >
                    {obj.name} <CheckIcon style={{ paddingLeft: 5 }} />
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    onClick={onChoose.bind(null, obj.id)}
                    variant="outlined"
                  >
                    {obj.name}
                  </Button>
                )
              }
            </Grid>
          );
        })}
      </Grid>
      <FormControl component="fieldset" error={error} >
        <FormHelperText className={classes.helperText}>{error && "กรุณาเลือกคำตอบ"}</FormHelperText>
      </FormControl>
    </div>
  )
}

export default React.forwardRef(OneChoiceQuestion)