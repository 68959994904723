import React from 'react';
import { QuestionnaireItemInputType } from '../../helpers/constants';
import TextQuestion from '../Questionaire/TextQuestion';
import NumberQuestion from '../Questionaire/NumberQuestion';
import OneChoiceQuestion from '../Questionaire/OneChoiceQuestion';
import MultipleChoiceQuestion from '../Questionaire/MultipleChoiceQuestion';

const MAP_COMPONENT = {
  [QuestionnaireItemInputType.TEXT]: TextQuestion,
  [QuestionnaireItemInputType.NUMBER]: NumberQuestion,
  [QuestionnaireItemInputType.ONE_CHOICE]: OneChoiceQuestion,
  [QuestionnaireItemInputType.MULTIPLE_CHOICE]: MultipleChoiceQuestion,
};

export default React.forwardRef((props, ref) => {
  const { question } = props;

  if (!question) return false;

  const QuestionComponent = MAP_COMPONENT[question.input_type];

  return <QuestionComponent ref={ref} center {...props} />;
});
