import React from 'react';
import { navigate } from 'gatsby';
import { getQuestion, postAnswer } from '../../apis/ai';
import { createSelectedSymptom } from '../../reducers/assessmentInfo';
import { QuestionnaireItemInputType } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import Layout from '../../components/Layout';
import DynamicAssessment from '../../components/Assessment/DynamicAssessment';

function transformQuestion(input) {
  if (input.type === 'bool') {
    return {
      id: input.id,
      input_type: QuestionnaireItemInputType.ONE_CHOICE,
      label: input.name,
      choices: (input.choice || [{
        value: '1',
        answer: 'ใช่'
      }, {
        value: '0',
        answer: 'ไม่ใช่'
      }]).map(val => {
        return {
          id: val.value,
          name: val.answer
        }
      })
    };
  }
  if (input.type === 'int') {
    return {
      id: input.id,
      input_type: QuestionnaireItemInputType.MULTIPLE_CHOICE,
      label: input.name,
      choices: (input.choice || []).map(val => {
        return {
          id: val.value,
          name: val.answer
        }
      })
    };
  }
  return {
    input_type: QuestionnaireItemInputType.ONE_CHOICE,
    label: input.name,
    choices: [
      {
        id: 'true',
        name: 'ใช่'
      }, {
        id: 'false',
        name: 'ไม่ใช่'
      }
    ]
  };
}

export default () => {
  const formRef = React.useRef(null);
  const [question, setQuestion] = React.useState();
  const [answer, setAnswer] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [maxStep, setMaxStep] = React.useState(30);
  const { assessmentId, skipAi, patientRecord } = useSelector(state => state.assessmentInfo);
  const selectedSymptom = useSelector(createSelectedSymptom);

  React.useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async (step) => {
    if (!selectedSymptom.length) {
      return;
    }

    setLoading(true);
    try {
      const { gender } = patientRecord;
      let res = await getQuestion(assessmentId, selectedSymptom.map(Number), gender === 'f' ? 'female' : 'male');
      setQuestion(transformQuestion(res));
    } catch (e) {
    }
    setLoading(false);
  }

  const onAnswerChange = (val) => {
    if (question.input_type === QuestionnaireItemInputType.ONE_CHOICE) {
      if (!loading) {
        setLoading(true);
        setAnswer(val);
        setTimeout(nextStep.bind(null, [val]), 400);
      }
    } else {
      setAnswer(val);
    }
  }

  const nextStep = async (val) => {
    if (!formRef.current.validate()) return;

    setLoading(true);
    try {
      const _answer = val == null ? answer : val; // allow val to be 0
      let res = await postAnswer(assessmentId, _answer, question.id);
      if (res.finish) {
        navigate(`/assessment/success?assessmentId=${assessmentId}`, {
          replace: true
        });
      } else {
        setAnswer();
        setQuestion(transformQuestion(res.question));
        setStep(res.current_step);
        setMaxStep(res.finish_step);
      }
    } catch (e) {
      alert('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
    }
    setLoading(false);
  }

  const backStep = () => {
    const prev = Math.max(0, step - 1);
    if (prev < 1) {
      window.history.back();
    } else {
      setStep(Math.max(0, step - 1));
    }
  }

  let percent = (step / maxStep) * 100;
  if (percent > 100) percent = 100;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>แบบประเมินสุขภาพ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <LinearProgressWithLabel value={percent} />
        <Box mt={4} maxWidth={800} ml="auto" mr="auto">
          <DynamicAssessment ref={formRef} question={question} setValue={onAnswerChange} data={answer} />
          {
            question && question.input_type !== QuestionnaireItemInputType.ONE_CHOICE && (
              <Box mt={8}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={() => nextStep()}
                >
                  ต่อไป
                </Button>
              </Box>
            )
          }
        </Box>
      </Box>
    </Layout>
  );
}
